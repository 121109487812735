<template>
  <div>
    <div class="vx-row p-1">
      <b-button size="sm" class="ml-5" variant="danger" @click="closePage">
        <feather-icon
            icon="XCircleIcon"
            class="mr-50"
        />
        Fechar
      </b-button>
      <b-button size="sm" class="ml-5" variant="success" @click="saveAndClose">
        <feather-icon
            icon="CheckCircleIcon"
            class="mr-50"
        />
        Salvar e fechar
      </b-button>
      <b-button size="sm" class="ml-5" variant="warning" @click="saveLayout">
        <feather-icon
            icon="UploadCloudIcon"
            class="mr-50"
        />
        Salvar layout
      </b-button>
    </div>
    <!-- EDITOR -->
    <div> 
    <EmailEditor
      :appearance="appearance"
      :project-id="projectId"
      :locale="locale"
      :tools="tools"
      :options="options"
      ref="emailEditor"
      v-on:load="editorLoaded"
    />
    </div>
  </div>
</template>

<script>
import {
  BButton
} from 'bootstrap-vue'
import { EmailEditor } from 'vue-email-editor'
import axios from "@axios"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    EmailEditor,
    BButton
  },
  data() {    
    return {  
      layout_id: undefined,
      layout_name: undefined,
      locale: "pt-BR",
      projectId: 6435, // replace with your project id
      displayMode: 'email',
      tools: {

      },
      options: {
        mergeTags: {
          clients: {
            name: "Cliente",
            mergeTags: {
              nome: {
                name: "Nome completo",
                value: "{{nome}}"
              },
              prim_nome: {
                name: "Primeiro nome",
                value: "{{prim_nome}}"
              },
              cpf_cnpj: {
                name: "CPF/CNPJ",
                value: "{{cpf_cnpj}}"
              }
            }
          },
          titles: {
            name: "Título",
            mergeTags: {
              tit_valor: {
                name: "Valor do título",
                value: "{{tit_valor}}"
              },
              tit_vencimento: {
                name: "Vencimento do título",
                value: "{{tit_vencimento}}"
              }
            }
          },
          credor: {
            name: "Credor",
            mergeTags: {
              credor_nome: {
                name: "Nome do credor",
                value: "{{credor_nome}}"
              },
              credor_cod: {
                name: "Código do credor",
                value: "{{credor_cod}}"
              },
              credor_email: {
                name: "Email do credor",
                value: "{{credor_email}}"
              },
              credor_tel: {
                name: "Telefone do credor",
                value: "{{credor_tel}}"
              }
            }
          },
          dates: {
            name: "Datas",
            mergeTags: {
              ano: {
                name: "Ano atual",
                value: "{{ano}}"
              },
              mes: {
                name: "Mês atual",
                value: "{{mes}}"
              },
              dia: {
                name: "Dia atual",
                value: "{{dia}}"
              },
              hoje: {
                name: "Hoje (dd/mm/yyyy)",
                value: "{{hoje}}"
              }
            }
          }
        }
      },
      appearance: {
        theme: 'dark',
        panels: {
          tools: {
            dock: 'right'
          }
        }
      } 
    }
  },
  methods: {
    loadLayout(){
      axios.get('email-layouts/'+this.layout_id)
      .then(({data}) => {
        this.layout_name = data.nome
        this.$refs.emailEditor.editor.loadDesign(JSON.parse(data.conteudo_json));
      })
      .catch(err => { 
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: `Erro ao carregar seu layout ):`,
          },
        })
      })
    },
    editorLoaded() {
      this.layout_id = this.$route.params.layoutId
      if(this.$route.params.layoutId != undefined){
        this.loadLayout()
      }else{
        var design = {"counters":{"u_row":4,"u_column":5,"u_content_text":1,"u_content_image":1,"u_content_button":1,"u_content_divider":1,"u_content_heading":1},"body":{"rows":[],"values":{"textColor":"#000000","backgroundColor":"#ffffff","backgroundImage":{"url":"","fullWidth":true,"repeat":false,"center":true,"cover":false},"contentWidth":"500px","contentAlign":"center","fontFamily":{"label":"Arial","value":"arial,helvetica,sans-serif"},"preheaderText":"","linkStyle":{"body":true,"linkColor":"#0000ee","linkHoverColor":"#0000ee","linkUnderline":true,"linkHoverUnderline":true},"_meta":{"htmlID":"u_body","htmlClassNames":"u_body"}}},"schemaVersion":6};
        this.$refs.emailEditor.editor.loadDesign(design);
      }      
    },
    closePage() {
      this.$router.push({ name: 'layouts-email-list' })
    },
    saveAndClose() {
      this.$refs.emailEditor.editor.exportHtml(
        (data) => {
          if(this.layout_id == undefined){
            axios.post('email-layouts', {
              nome: "Layout Email",
              conteudo_json: JSON.stringify(data.design),
              conteudo_html: data.html              
            })
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Sucesso',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: `Seu layout foi criado com sucesso.`,
                },
              })
              this.$router.push({ name: 'layouts-email-list' })
            })
            .catch(err => { 
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Erro',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: `Erro ao atualizar seu layout ):`,
                },
              })
            })
          }else{
            axios.put('email-layouts/'+this.layout_id, {
              nome: this.layout_name,
              conteudo_json: JSON.stringify(data.design),
              conteudo_html: data.html              
            })
            .then((response) => {   
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Sucesso',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: `Seu layout foi atualizado com sucesso.`,
                },
              })
              this.$router.push({ name: 'layouts-email-list' })
            })
            .catch(err => { 
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Erro',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: `Erro ao carregar seu layout ):`,
                },
              })
            })
          }   
        }
      )         
    },
    saveLayout() {
      this.$refs.emailEditor.editor.exportHtml(
        (data) => {
          if(this.layout_id == undefined){
            axios.post('email-layouts', {
              nome: "Layout Email",
              conteudo_json: JSON.stringify(data.design),
              conteudo_html: data.html              
            })
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Sucesso',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: `Seu layout foi criado com sucesso.`,
                },
              })
              this.layout_id = response.data.id
            })
            .catch(err => { 
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Erro',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: `Erro ao carregar seu layout ):`,
                },
              })
            })
          }else{
            axios.put('email-layouts/'+this.layout_id, {
              nome: this.layout_name,
              conteudo_json: JSON.stringify(data.design),
              conteudo_html: data.html              
            })
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Sucesso',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: `Seu layout foi atualizado com sucesso.`,
                },
              })
            })
            .catch(err => { 
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Erro',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: `Erro ao carregar seu layout ):`,
                },
              })
            })
          }
        }
      )
    }
  }
}
</script>

<style>
iframe{
  height: 100vh !important;
}
</style>
